import React from 'react';
import SNTLogoIcon from './logo/snt-icon.svg';


const LogoIcon = () => {
  return (
    <img
      src={SNTLogoIcon}
      alt="Springer Nature Technology Logo"
      style={{height: "30px"}}
      />
  );
};

export default LogoIcon;
