import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

export class JsonTechRadarClient implements TechRadarApi {
  async load(_: string | undefined): Promise<TechRadarLoaderResponse> {
    const data = await fetch('/tech-radar.json').then(res => res.json());

    // converts the timeline dates into date objects
    return {
      ...data,
      entries: data.entries.map((entry: { timeline: any[]; }) => ({
        ...entry,
        timeline: entry.timeline.map(timeline => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }
}
