import {
    HomePageToolkit,
    HomePageCompanyLogo,
    HomePageStarredEntities,
    HomePageTopVisited,
    HomePageRecentlyVisited,
  } from '@backstage/plugin-home';
  import { wrapInTestApp, TestApiProvider } from '@backstage/test-utils';
  import { Content, Page, InfoCard } from '@backstage/core-components';
  import {
    starredEntitiesApiRef,
    MockStarredEntitiesApi,
    entityRouteRef,
    catalogApiRef,
  } from '@backstage/plugin-catalog-react';
  import { configApiRef } from '@backstage/core-plugin-api';
  import { ConfigReader } from '@backstage/config';
  import { HomePageSearchBar, searchPlugin } from '@backstage/plugin-search';
  import {
    searchApiRef,
    SearchContextProvider,
  } from '@backstage/plugin-search-react';
  import { Grid, makeStyles } from '@material-ui/core';
  import React, { ComponentType, PropsWithChildren } from 'react';
import { Typography } from '@material-ui/core';
import SNTLogoSVG from './images/snt-logo.svg';
import EELogoSVG from './images/ee-white.png';
import SNDLogoSVG from './images/snd-logo.svg';
import { Link } from 'react-router-dom';

const LogoHomePage = () => {
  return (
    <div style={{textAlign:"center"}}>
        <img
        src={SNTLogoSVG}
        alt="Springer Nature Technology Logo"
        style={{height: "60px"}}
        />

      <Typography component="h2">
        <b>Engineering Catalogue</b>
      </Typography>
    </div>
  );
};

const EELogo = () => {
    return (
      <img
        src={EELogoSVG}
        alt="Engineering Enablement Logo"
        style={{width: "44px"}}
        />
    );
  };

const SNDLogo = () => {
    return (
    <img
        src={SNDLogoSVG}
        alt="Springer Nature Digital Logo"
        style={{width: "44px"}}
        />
    );
};

  
  const entities = [
    {
      apiVersion: '1',
      kind: 'Component',
      metadata: {
        name: 'mock-starred-entity',
        title: 'Mock Starred Entity!',
      },
    },
    {
      apiVersion: '1',
      kind: 'Component',
      metadata: {
        name: 'mock-starred-entity-2',
        title: 'Mock Starred Entity 2!',
      },
    },
    {
      apiVersion: '1',
      kind: 'Component',
      metadata: {
        name: 'mock-starred-entity-3',
        title: 'Mock Starred Entity 3!',
      },
    },
    {
      apiVersion: '1',
      kind: 'Component',
      metadata: {
        name: 'mock-starred-entity-4',
        title: 'Mock Starred Entity 4!',
      },
    },
  ];
  
  const mockCatalogApi = {
    getEntities: async () => ({ items: entities }),
  };
  
  const starredEntitiesApi = new MockStarredEntitiesApi();
  starredEntitiesApi.toggleStarred('component:default/example-starred-entity');
  starredEntitiesApi.toggleStarred('component:default/example-starred-entity-2');
  starredEntitiesApi.toggleStarred('component:default/example-starred-entity-3');
  starredEntitiesApi.toggleStarred('component:default/example-starred-entity-4');
  
  export default {
    title: 'Plugins/Home/Templates',
    decorators: [
      (Story: ComponentType<PropsWithChildren<{}>>) =>
        wrapInTestApp(
          <>
            <TestApiProvider
              apis={[
                [catalogApiRef, mockCatalogApi],
                [starredEntitiesApiRef, starredEntitiesApi],
                [searchApiRef, { query: () => Promise.resolve({ results: [] }) }],
                [
                  configApiRef,
                  new ConfigReader({}),
                ],
              ]}
            >
              <Story />
            </TestApiProvider>
          </>,
          {
            mountedRoutes: {
              '/hello-company': searchPlugin.routes.root,
              '/catalog/:namespace/:kind/:name': entityRouteRef,
            },
          },
        ),
    ],
  };
  
  const useStyles = makeStyles(theme => ({
    searchBarInput: {
      maxWidth: '60vw',
      margin: 'auto',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '50px',
      boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
      borderStyle: 'none'
    },
    link: {
      textDecoration: 'underline',
      color: theme.palette.link
    }
  }));
  
  const useLogoStyles = makeStyles(theme => ({
    container: {
      margin: theme.spacing(5, 0),
    },
    svg: {
      width: 'auto',
      height: 100,
    },
    path: {
      fill: '#7df3e1',
    },
  }));
  
  export const HomePage = () => {
    const classes = useStyles();
    const { container } = useLogoStyles();
  
    return (
      <SearchContextProvider>
        <Page themeId="home">
          <Content>
            <Grid container justifyContent="center" spacing={6}>
              <HomePageCompanyLogo
                className={container}
                logo={<LogoHomePage />}
              />
              <Grid container item xs={12} justifyContent='center'>
                <HomePageSearchBar
                  InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline }}}
                  placeholder="Search"
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} md={6}>
                  <InfoCard title="About">
                    <p>
                        This is the engineering service catalogue for SN Technology. It's primarily an inventory of our services, APIs, and applications; the documentation for these units; and of the relationships between them.
                    </p>
                    <p>
                        Interested in bringing your systems into the catalogue? Start with the <Link to="/docs/default/component/engineering-catalogue/onboarding/" className={classes.link}>onboarding guide</Link>.
                    </p>
                    <p>
                        See <Link to="/docs/default/component/engineering-catalogue" className={classes.link}>Introduction — Engineering Catalogue</Link> for more information about the what, how, and why we have an engineering service catalogue.
                    </p>
                  </InfoCard>
                </Grid>
                <Grid item xs={12} md={6}>
                  <HomePageToolkit
                    title='Handy Links'
                    tools={[
                        {
                            url: 'https://ee.public.springernature.app/',
                            label: 'Engineering Enablement Docs',
                            icon: <EELogo />,
                        },
                        {
                            url: 'https://snd.public.springernature.app/',
                            label: 'SND Directory',
                            icon: <SNDLogo />,
                        },
                        {
                            url: 'https://snd-backend-playbook.public.springernature.app/',
                            label: 'SND Backend Playbook',
                            icon: <SNDLogo />,
                        },
                        {
                            url: 'https://github.com/springernature/frontend-playbook',
                            label: 'SND Frontend Playbook',
                            icon: <SNDLogo />,
                        },
                        {
                            url: 'https://quality-analysts-playbook.public.springernature.app/',
                            label: 'SND QA Playbook',
                            icon: <SNDLogo />,
                        }
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <HomePageStarredEntities />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageTopVisited />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageRecentlyVisited />
                </Grid>
              </Grid>
            </Grid>
          </Content>
        </Page>
      </SearchContextProvider>
    );
  };
