import React from 'react';

import SNTLogoFull from './logo/snt-logo.svg';

const LogoFull = () => {
  return (
    <img
      src={SNTLogoFull}
      alt="Springer Nature Technology Logo"
      style={{height: "60px"}}
      />
  );
};

export default LogoFull;
